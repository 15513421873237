import { createTheme } from './config';
import { IColorPalettes, IThemeConfig } from './types';

const SPACING_CONFIG: IThemeConfig['spacing'] = {
  8: '8px',
  12: '12px',
  16: '16px',
  24: '24px',
  32: '32px',
  40: '40px',
  48: '48px',
  56: '56px',
  64: '64px',
  80: '80px',
};

export const BREAKPOINTS_CONFIG: IThemeConfig['breakpoints'] = {
  xs: 375,
  /**
   * [M]obile
   */
  sm: 600,
  /**
   * [T]ablet
   */
  md: 900,
  /**
   * [D]esktop
   */
  lg: 1440,
  xl: 1920,
};

const createDarkColorPalettes = (
  accentPalette: IColorPalettes['accent']
): IThemeConfig['color'] => ({
  primary: {
    '100': '#080808',
    '95': '#141414',
    '90': '#1D1E1E',
    '85': '#292929',
    '80': '#303030',
    '75': '#3D3D3D',
    '70': '#474747',
    '65': '#525252',
    '60': '#5C5C5C',
    '55': '#666666',
    '50': '#707070',
    '45': '#7A7A7A',
    '40': '#858585',
    '35': '#8F8F8F',
    '30': '#999999',
    '25': '#ADADAD',
    '20': '#C2C2C2',
    '15': '#CCCCCC',
    '10': '#D6D6D6',
    '5': '#E0E0E0',
    '0': '#FFFFFF',
    'Dark 90%': 'rgba(8, 8, 8, 0.9)',
    'Dark 80%': 'rgba(8, 8, 8, 0.8)',
    'Dark 70%': 'rgba(8, 8, 8, 0.7)',
    'Dark 60%': 'rgba(8, 8, 8, 0.6)',
    'Dark 50%': 'rgba(8, 8, 8, 0.5)',
    'Dark 40%': 'rgba(8, 8, 8, 0.4)',
    'Dark 30%': 'rgba(8, 8, 8, 0.3)',
    'Dark 20%': 'rgba(8, 8, 8, 0.2)',
    'Dark 10%': 'rgba(8, 8, 8, 0.1)',
    'Dark 5%': 'rgba(8, 8, 8, 0.05)',
    'Light 90%': 'rgba(255, 255, 255, 0.9)',
    'Light 80%': 'rgba(255, 255, 255, 0.8)',
    'Light 70%': 'rgba(255, 255, 255, 0.7)',
    'Light 60%': 'rgba(255, 255, 255, 0.6)',
    'Light 50%': 'rgba(255, 255, 255, 0.5)',
    'Light 40%': 'rgba(255, 255, 255, 0.4)',
    'Light 30%': 'rgba(255, 255, 255, 0.3)',
    'Light 20%': 'rgba(255, 255, 255, 0.2)',
    'Light 10%': 'rgba(255, 255, 255, 0.1)',
    'Light 5%': 'rgba(255, 255, 255, 0.05)',
  },
  accent: accentPalette,
  success: {
    '90': '#00120C',
    '80': '#012819',
    '70': '#015032',
    '60': '#02794B',
    '50': '#02935C',
    '40': '#03DD8A',
    '30': '#36FCB0',
    '20': '#AFFEDF',
    '10': '#EBFFF7',
    '90%': 'rgba(2, 179, 92, 0.9)',
    '80%': 'rgba(2, 179, 92, 0.8)',
    '70%': 'rgba(2, 179, 92, 0.7)',
    '60%': 'rgba(2, 179, 92, 0.6)',
    '50%': 'rgba(2, 179, 92, 0.5)',
    '40%': 'rgba(2, 179, 92, 0.4)',
    '30%': 'rgba(2, 179, 92, 0.3)',
    '20%': 'rgba(2, 179, 92, 0.2)',
    '10%': 'rgba(2, 179, 92, 0.1)',
    '5%': 'rgba(2, 179, 92, 0.05)',
  },
  warning: {
    '90': '#130901',
    '80': '#391C04',
    '70': '#723808',
    '60': '#BF5D0D',
    '50': '#F08022',
    '40': '#F39B53',
    '30': '#F7BC8D',
    '20': '#FAD3B3',
    '10': '#FEF4EC',
    '90%': 'rgba(240, 128, 32, 0.9)',
    '80%': 'rgba(240, 128, 32, 0.8)',
    '70%': 'rgba(240, 128, 32, 0.7)',
    '60%': 'rgba(240, 128, 32, 0.6)',
    '50%': 'rgba(240, 128, 32, 0.5)',
    '40%': 'rgba(240, 128, 32, 0.4)',
    '30%': 'rgba(240, 128, 32, 0.3)',
    '20%': 'rgba(240, 128, 32, 0.2)',
    '10%': 'rgba(240, 128, 32, 0.1)',
    '5%': 'rgba(240, 128, 32, 0.05)',
  },
  error: {
    '90': '#120303',
    '80': '#470C0B',
    '70': '#8E1715',
    '60': '#D52320',
    '50': '#E23F3C',
    '40': '#E7625F',
    '30': '#EF9695',
    '20': '#F7CBCA',
    '10': '#FCEEED',
    '90%': 'rgba(226, 63, 60, 0.9)',
    '80%': 'rgba(226, 63, 60, 0.8)',
    '70%': 'rgba(226, 63, 60, 0.7)',
    '60%': 'rgba(226, 63, 60, 0.6)',
    '50%': 'rgba(226, 63, 60, 0.5)',
    '40%': 'rgba(226, 63, 60, 0.4)',
    '30%': 'rgba(226, 63, 60, 0.3)',
    '20%': 'rgba(226, 63, 60, 0.2)',
    '10%': 'rgba(226, 63, 60, 0.1)',
    '5%': 'rgba(226, 63, 60, 0.05)',
  },
  info: {
    '90': '#081421',
    '80': '#0B1F32',
    '70': '#173D64',
    '60': '#1E5285',
    '50': '#296EB4',
    '40': '#5999D9',
    '30': '#7AADE1',
    '20': '#ACCCEC',
    '10': '#DEEBF7',
    '90%': 'rgba(41, 110, 180, 0.9)',
    '80%': 'rgba(41, 110, 180, 0.8)',
    '70%': 'rgba(41, 110, 180, 0.7)',
    '60%': 'rgba(41, 110, 180, 0.6)',
    '50%': 'rgba(41, 110, 180, 0.5)',
    '40%': 'rgba(41, 110, 180, 0.4)',
    '30%': 'rgba(41, 110, 180, 0.3)',
    '20%': 'rgba(41, 110, 180, 0.2)',
    '10%': 'rgba(41, 110, 180, 0.1)',
    '5%': 'rgba(41, 110, 180, 0.05)',
  },
  virtual: {
    '90': '#241159',
    '80': '#3D1F93',
    '70': '#562AD5',
    '60': '#775CFF',
    '50': '#9580FF',
    '40': '#AA99FF',
    '30': '#BBADFF',
    '20': '#DDD6FF',
    '10': '#EEEBFF',
    '90%': 'rgba(149, 128, 255, 0.9)',
    '80%': 'rgba(149, 128, 255, 0.8)',
    '70%': 'rgba(149, 128, 255, 0.7)',
    '60%': 'rgba(149, 128, 255, 0.6)',
    '50%': 'rgba(149, 128, 255, 0.5)',
    '40%': 'rgba(149, 128, 255, 0.4)',
    '30%': 'rgba(149, 128, 255, 0.3)',
    '20%': 'rgba(149, 128, 255, 0.2)',
    '10%': 'rgba(149, 128, 255, 0.1)',
    '5%': 'rgba(149, 128, 255, 0.05)',
  },
});

/**
 * Source of truth for design system
 */
export const FOOTBALL_THEME = createTheme({
  color: createDarkColorPalettes({
    '90': '#101401',
    '80': '#506303',
    '70': '#BDDA49',
    '60': '#CEEE4B',
    '50': '#DAFB55',
    '40': '#E5FC88',
    '30': '#EDFDAF',
    '20': '#F2FDC3',
    '10': '#FBFFE8',
    '90%': 'rgba(218, 251, 85, 0.9)',
    '80%': 'rgba(218, 251, 85, 0.8)',
    '70%': 'rgba(218, 251, 85, 0.7)',
    '60%': 'rgba(218, 251, 85, 0.6)',
    '50%': 'rgba(218, 251, 85, 0.5)',
    '40%': 'rgba(218, 251, 85, 0.4)',
    '30%': 'rgba(218, 251, 85, 0.3)',
    '20%': 'rgba(218, 251, 85, 0.2)',
    '10%': 'rgba(218, 251, 85, 0.1)',
    '5%': 'rgba(218, 251, 85, 0.05)',
  }),
  breakpoints: BREAKPOINTS_CONFIG,
  spacing: SPACING_CONFIG,
});

export const BASKETBALL_THEME = createTheme({
  color: createDarkColorPalettes({
    '90': '#291000',
    '80': '#8F3700',
    '70': '#F55E00',
    '60': '#FF751F',
    '50': '#FF873C',
    '40': '#FFA770',
    '30': '#FFCDAD',
    '20': '#FFE6D6',
    '10': '#FFF2EB',
    '90%': 'rgba(255, 135, 60, 0.9)',
    '80%': 'rgba(255, 135, 60, 0.8)',
    '70%': 'rgba(255, 135, 60, 0.7)',
    '60%': 'rgba(255, 135, 60, 0.6)',
    '50%': 'rgba(255, 135, 60, 0.5)',
    '40%': 'rgba(255, 135, 60, 0.4)',
    '30%': 'rgba(255, 135, 60, 0.3)',
    '20%': 'rgba(255, 135, 60, 0.2)',
    '10%': 'rgba(255, 135, 60, 0.1)',
    '5%': 'rgba(255, 135, 60, 0.05)',
  }),
  breakpoints: BREAKPOINTS_CONFIG,
  spacing: SPACING_CONFIG,
});

export const CL1_THEME = createTheme({
  color: {
    primary: {
      '0': '#FFFFFF',
      '5': '#F4F4F4',
      '10': '#E8E8E8',
      '15': '#DCDCDC',
      '20': '#D0D0D0',
      '25': '#C4C4C4',
      '30': '#B8B8B8',
      '35': '#ACACAC',
      '40': '#A0A0A0',
      '45': '#949494',
      '50': '#888888',
      '55': '#7C7C7C',
      '60': '#707070',
      '65': '#646464',
      '70': '#585858',
      '75': '#4C4C4C',
      '80': '#404040',
      '85': '#343434',
      '90': '#2E2E2E',
      '95': '#262626',
      '100': '#1A1A1A',
      'Dark 90%': 'rgba(8, 8, 8, 0.9)',
      'Dark 80%': 'rgba(8, 8, 8, 0.8)',
      'Dark 70%': 'rgba(8, 8, 8, 0.7)',
      'Dark 60%': 'rgba(8, 8, 8, 0.6)',
      'Dark 50%': 'rgba(8, 8, 8, 0.5)',
      'Dark 40%': 'rgba(8, 8, 8, 0.4)',
      'Dark 30%': 'rgba(8, 8, 8, 0.3)',
      'Dark 20%': 'rgba(8, 8, 8, 0.2)',
      'Dark 10%': 'rgba(8, 8, 8, 0.1)',
      'Dark 5%': 'rgba(8, 8, 8, 0.05)',
      'Light 90%': 'rgba(255, 255, 255, 0.9)',
      'Light 80%': 'rgba(255, 255, 255, 0.8)',
      'Light 70%': 'rgba(255, 255, 255, 0.7)',
      'Light 60%': 'rgba(255, 255, 255, 0.6)',
      'Light 50%': 'rgba(255, 255, 255, 0.5)',
      'Light 40%': 'rgba(255, 255, 255, 0.4)',
      'Light 30%': 'rgba(255, 255, 255, 0.3)',
      'Light 20%': 'rgba(255, 255, 255, 0.2)',
      'Light 10%': 'rgba(255, 255, 255, 0.1)',
      'Light 5%': 'rgba(255, 255, 255, 0.05)',
    },
    accent: {
      '90': '#001558',
      '80': '#00258B',
      '70': '#0640CC',
      '60': '#074CE6',
      '50': '#085FFF',
      '40': '#99C3FF',
      '30': '#B3D2FF',
      '20': '#CCE1FF',
      '10': '#E6F0FF',
      '90%': 'rgba(8, 95, 255, 0.9)',
      '80%': 'rgba(8, 95, 255, 0.8)',
      '70%': 'rgba(8, 95, 255, 0.7)',
      '60%': 'rgba(8, 95, 255, 0.6)',
      '50%': 'rgba(8, 95, 255, 0.5)',
      '40%': 'rgba(8, 95, 255, 0.4)',
      '30%': 'rgba(8, 95, 255, 0.3)',
      '20%': 'rgba(8, 95, 255, 0.2)',
      '10%': 'rgba(8, 95, 255, 0.1)',
      '5%': 'rgba(8, 95, 255, 0.05)',
    },
    success: {
      '90': '#00120C',
      '80': '#012819',
      '70': '#015032',
      '60': '#02794B',
      '50': '#02935C',
      '40': '#03DD8A',
      '30': '#36FCB0',
      '20': '#AFFEDF',
      '10': '#EBFFF7',
      '90%': 'rgba(50, 147, 2, 0.9)',
      '80%': 'rgba(50, 147, 2, 0.8)',
      '70%': 'rgba(50, 147, 2, 0.7)',
      '60%': 'rgba(50, 147, 2, 0.6)',
      '50%': 'rgba(50, 147, 2, 0.5)',
      '40%': 'rgba(50, 147, 2, 0.4)',
      '30%': 'rgba(50, 147, 2, 0.3)',
      '20%': 'rgba(50, 147, 2, 0.2)',
      '10%': 'rgba(50, 147, 2, 0.1)',
      '5%': 'rgba(50, 147, 2, 0.05)',
    },
    warning: {
      '90': '#130901',
      '80': '#391C04',
      '70': '#723808',
      '60': '#BF5D0D',
      '50': '#F08022',
      '40': '#F39B53',
      '30': '#F7BC8D',
      '20': '#FAD3B3',
      '10': '#FEF4EC',
      '90%': 'rgba(240, 165, 34 , 0.9)',
      '80%': 'rgba(240, 165, 34 , 0.8)',
      '70%': 'rgba(240, 165, 34 , 0.7)',
      '60%': 'rgba(240, 165, 34 , 0.6)',
      '50%': 'rgba(240, 165, 34 , 0.5)',
      '40%': 'rgba(240, 165, 34 , 0.4)',
      '30%': 'rgba(240, 165, 34 , 0.3)',
      '20%': 'rgba(240, 165, 34 , 0.2)',
      '10%': 'rgba(240, 165, 34 , 0.1)',
      '5%': 'rgba(240, 165, 34 , 0.05)',
    },
    error: {
      '90': '#120303',
      '80': '#470C0B',
      '70': '#8E1715',
      '60': '#D52320',
      '50': '#E23F3C',
      '40': '#E7625F',
      '30': '#EF9695',
      '20': '#F7CBCA',
      '10': '#FCEEED',
      '90%': 'rgba(255, 17, 17, 0.9)',
      '80%': 'rgba(255, 17, 17, 0.8)',
      '70%': 'rgba(255, 17, 17, 0.7)',
      '60%': 'rgba(255, 17, 17, 0.6)',
      '50%': 'rgba(255, 17, 17, 0.5)',
      '40%': 'rgba(255, 17, 17, 0.4)',
      '30%': 'rgba(255, 17, 17, 0.3)',
      '20%': 'rgba(255, 17, 17, 0.2)',
      '10%': 'rgba(255, 17, 17, 0.1)',
      '5%': 'rgba(255, 17, 17, 0.05)',
    },
    info: {
      '90': '#081421',
      '80': '#0B1F32',
      '70': '#173D64',
      '60': '#1E5285',
      '50': '#296EB4',
      '40': '#5999D9',
      '30': '#7AADE1',
      '20': '#ACCCEC',
      '10': '#DEEBF7',
      '90%': 'rgba(0, 135, 255, 0.9)',
      '80%': 'rgba(0, 135, 255, 0.8)',
      '70%': 'rgba(0, 135, 255, 0.7)',
      '60%': 'rgba(0, 135, 255, 0.6)',
      '50%': 'rgba(0, 135, 255, 0.5)',
      '40%': 'rgba(0, 135, 255, 0.4)',
      '30%': 'rgba(0, 135, 255, 0.3)',
      '20%': 'rgba(0, 135, 255, 0.2)',
      '10%': 'rgba(0, 135, 255, 0.1)',
      '5%': 'rgba(0, 135, 255, 0.05)',
    },
    virtual: {
      '90': '#241159',
      '80': '#3D1F93',
      '70': '#562AD5',
      '60': '#775CFF',
      '50': '#9580FF',
      '40': '#AA99FF',
      '30': '#BBADFF',
      '20': '#DDD6FF',
      '10': '#EEEBFF',
      '90%': 'rgba(149, 128, 255, 0.9)',
      '80%': 'rgba(149, 128, 255, 0.8)',
      '70%': 'rgba(149, 128, 255, 0.7)',
      '60%': 'rgba(149, 128, 255, 0.6)',
      '50%': 'rgba(149, 128, 255, 0.5)',
      '40%': 'rgba(149, 128, 255, 0.4)',
      '30%': 'rgba(149, 128, 255, 0.3)',
      '20%': 'rgba(149, 128, 255, 0.2)',
      '10%': 'rgba(149, 128, 255, 0.1)',
      '5%': 'rgba(149, 128, 255, 0.05)',
    },
  },
  breakpoints: BREAKPOINTS_CONFIG,
  spacing: SPACING_CONFIG,
});
